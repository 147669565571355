import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { doc, getDoc, where, collection, query, onSnapshot } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { firestore } from "./Firebase";
import BackButton from "./BackButton";
import Logo from "./images/logo.png";
import Footer from "./Footer";

function CategoryDetail() {
  const navigate = useNavigate();
  const { categoryId } = useParams();
  const [category, setCategory] = useState();
  const [subcategories, setSubcategories] = useState([]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const q = query(collection(firestore, "categories"), where("parent", "==", categoryId));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fetchSubcategories = async () => {
        try {
          const storage = getStorage();
          const subcategoriesPromises = querySnapshot.docs.map(async (doc) => {
            const subcategory = doc.data();
            if (!subcategory.thumbnailImage)
              return { id: subcategory.id, name: subcategory.translations.sv };
            const imageUrl = await getDownloadURL(ref(storage, subcategory.thumbnailImage));
            return { id: subcategory.id, imageUrl, name: subcategory.translations.sv };
          });
          setSubcategories(await Promise.all(subcategoriesPromises));
        } catch (err) {
          console.log(err);
        }
      };

      fetchSubcategories();
    });

    return () => unsubscribe();
  }, [categoryId]);

  useEffect(() => {
    const storage = getStorage();
    const fetchCategory = async () => {
      const docRef = doc(firestore, "categories", categoryId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setCategory(data);
        if (data.images) {
          const imagesPromises = data.images.filter((image) => image.thumbnailImage !== undefined).map(async (image) => {
            const imageUrl = await getDownloadURL(ref(storage, image.thumbnailImage));
            return { id: image.id, imageUrl, description: image?.description?.sv };
          });
          setImages(await Promise.all(imagesPromises));
        }
      } else {
        console.log("No such document!");
      }
    };

    fetchCategory();
  }, [categoryId]);

  const handleClose = () => {
    navigate(-1);
  };

  return (
    <div className="category-detail">
      <BackButton onClick={handleClose} />
      <div className="category-header">
      <Link to={`/`} key="home">
        <img src={Logo} alt="Logo" className="small-logo" />
        </Link>
      {category && <div className="page-title">{category.translations.sv}</div>}
      </div>
      
      <div className="image-grid">
        {subcategories.map((subcategory) => (
          <Link to={`/category/${subcategory.id}`} key={subcategory.id}>
            <div className="grid-box">
              <img src={subcategory.imageUrl} alt={subcategory.name} />
            </div>
            <div className="category-description">{subcategory.name}</div>
          </Link>
        ))}
        {images.map((image) => (
          <Link to={`/image/${image.id}`} key={image.id}>
            <div className="grid-box">
              <img src={image.imageUrl} alt={image.description} />
            </div>
          </Link>
        ))}
      </div>
      <Footer />
    </div>
  );
}

export default CategoryDetail;