import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { doc, onSnapshot } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { firestore } from "./Firebase";
import BackButton from "./BackButton";
import PrintButton from "./PrintButton";
import Logo from "./images/logo.png";
import { CircularProgress } from "@mui/material";
import MessageTicker from "./MessageTicker";
import MemoryGame from "./MemoryGame";
import Footer from "./Footer";

function ImageDetail() {
  const navigate = useNavigate();
  const { imageId } = useParams();
  const [image, setImage] = useState(null);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showMemory, setShowMemory] = useState(false);

  useEffect(() => {
    const storage = getStorage();
    const docRef = doc(firestore, "images", imageId);
    setLoading(true);

    const unsubscribe = onSnapshot(
      docRef,
      (docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.image) {
            const storageRef = ref(storage, data.image);
            getDownloadURL(storageRef)
              .then((imageUrl) => {
                setImage({ url: imageUrl, description: data.description.sv });
                setCategories(data.categories || []);
                setLoading(false);
              })
              .catch((error) => {
                console.error("Error getting document image URL:", error);
                setLoading(false);
              });
          } else {
            console.log("Document is missing 'image' field or it is invalid.");
            setShowMemory(true);
          }
        } else {
          console.log("No such document!");
          setShowMemory(true);
        }
      },
      (error) => {
        console.error("Error subscribing to document:", error);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [imageId]);

  const handleClose = () => {
    navigate(-1);
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="image-detail-container">
      <BackButton onClick={handleClose} />
      <PrintButton onClick={handlePrint} />
      {loading && showMemory && (
        <div>
          <div className="loading-indicator-container">
            <MessageTicker
              messages={[
                "Spela memory så skapar jag bilden",
                "Letar efter pennorna?!",
                "Måla måla måla...",
                "Det här blir fint!",
                "Vänta lite så är jag snart klar...",
                "... bara lite till...",
                "Jag är snart klar...",
              ]}
              interval={5000}
            />
            <CircularProgress />
          </div>
          <MemoryGame />
          
        </div>
      )}
      {image && (
        <div className="image-container">
          <img
            src={image.url}
            alt={image.description}
            className="scaled-image"
          />
          <div className="image-title no-print">{image.description}</div>
          <div className="category-chips no-print">
            {categories.map((category) => (
              <Link to={`/category/${category.id}`} key={category.id} className="chip">
                {category.translations.sv}
              </Link>
            ))}
          </div>
          <Footer />
        </div>
      )}

      <img
        src={Logo}
        alt="Logo"
        className="high-res-img only-print print-logo"
      />
    </div>
  );
}

export default ImageDetail;